:root {
  --intercom-operator-message-bg: #f2f2f2;
  --intercom-operator-message-text: #000;
  --intercom-reply-text-placeholder: #737376;
  --intercom-stamp-text: #737376;
  --intercom-user-bg: #3047ec;
  --intercom-user-message-text: #fff;
  --white: #fff;
}
/*# sourceMappingURL=index.e91c9a31.css.map */
